import $ from "jquery";

// smooth scroll
$(function () {
    $('a[href^="#"]')
        .on('click', function () {
            var adjust = 0;
            var speed = 400;
            var href = $(this).attr('href');
            var target = $(href == '#' || href == '' ? 'html' : href);
            var position = target.offset().top + adjust;
            $('body,html').animate({
                scrollTop: position
            }, speed, 'swing');
            return false;
        });
});

//Window height
$(window).on('load resize', function () {
    var hdr = $('.main-header');
    var hdrHeight = hdr.outerHeight(true);
    var h = $(window).height();
    var cont = $('#kv').outerHeight(true);
    if (cont < h) {
        $('#kv .inner , .kv_bg .owl-item').css('height', h - hdrHeight);
    }

});


//accordion menu
$(function () {
    $('.faq_list dt').on('click', function () {
        $(this).next().slideToggle();
        $(this).toggleClass('open');
    });
});

//nav
$(function () {
    $('.menuTrigger').on('click', function () {
        $(this).toggleClass('open');
        $('.head-nav').toggleClass('open');
    });
    $('header .nav a').on('click', function () {
        $('.head-nav').toggleClass('open');
    });
});


//KV Background Carousel
$('.imgs').owlCarousel({
    items: 1,
    loop: true,
    center: true,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplaySpeed: 5000,
    dots: false,
    mouseDrag: false,
    touchDrag: false,
    animateOut: 'fadeOut'
});

//Step carousel
var $owl = $('.step_list');
$owl.owlCarousel({
    items: 1,
    URLhashListener: true,
    dots: true,
    autoplayHoverPause: true,
    autoplay: true,
    autoplayTimeout: 10000,
    autoplaySpeed: 2000,
    loop: true,
    animateOut: 'fadeOut',
    nav: true,
    navText: '',
    responsive: {
        0: {
            nav: true,
            navText: '',
            dots: false
        },
    },

});

$(function () {
    $owl.on('changed.owl.carousel', function (event) {
        var cont = $owl.find('li').eq(event.item.index);
        var hash = cont.data('hash');

        $('.slider_nav li').removeClass('active');
        $('.slider_nav li[data-hash="' + hash + '"]').toggleClass('active');

    });
});


//think list Carousel
$('.think_list').owlCarousel({
    items: 1,
    loop: true,
    center: true,
    dots: true,
    nav: true,
    navText: '',
    autoplayHoverPause: true,
    autoplay: true,
    autoplayTimeout: 10000,
    autoplaySpeed: 2000,
});